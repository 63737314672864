export interface Company {
  name: string;
  id: string;
  icon: string;
  url: string;
  description?: string;
  website?: string;
  remote?: string;
  employees?: string;
  countries?: string[];
  publishedJobs?: number;
}

export const companies: Company[] = [
  {
    name: "Google",
    icon: "/google.svg",
    id: "google",
    url: "https://google.com",
  },
  {
    name: "Meta",
    icon: "/Meta_Logo-860x561.jpg",
    id: "meta",
    url: "https://metacareers.com",
    remote: "Yes",
  },
  {
    name: "Apple",
    icon: "/apple.svg",
    id: "apple",
    url: "https://apple.com",
  },
  {
    name: "Amazon",
    icon: "/amazon.svg",
    id: "amazon",
    url: "https://amazon.com",
  },
  {
    name: "Microsoft",
    icon: "/1200px-Microsoft_logo.svg.png",
    id: "microsoft",
    url: "https://microsoft.com",
  },
  {
    name: "Netflix",
    id: "netflix",
    icon: "/nficon2016.png",
    employees: "13,000+ (3,000 engineers)",
    remote: "No",
    countries: ["us", "sg", "nl"],
    publishedJobs: 222,
    url: "https://netflix.com/",
  },
  {
    name: "Reddit",
    id: "reddit",
    icon: "/android-icon-192x192.png",
    remote: "Yes",
    employees: "2,000+",
    countries: ["us", "ca", "ie", "nl", "de", "gb"], // TODO: Set in DB table after parsing
    publishedJobs: 222,
    url: "https://reddit.com/",
  },
  {
    name: "Spotify",
    id: "spotify",
    icon: "/1200px-Spotify_logo_without_text.svg.png",
    employees: "5,000+",
    remote: "No",
    countries: [],
    publishedJobs: 222,
    url: "https://spotify.com/",
  },
  //{
  //name: "Twitter",
  //icon: "/twitter.ico",
  //},
  {
    name: "GitHub",
    id: "github",
    icon: "/25231.png",
    employees: "2,500+",
    remote: "Yes",
    countries: ["au", "es", "gb", "us", "de"],
    publishedJobs: 222,
    url: "https://github.com",
  },
  {
    name: "HubSpot",
    id: "hubspot",
    icon: "/hubspot.png",
    remote: "Yes",
    employees: "7,500+",
    countries: ["us", "ie", "de", "es", "gb"],
    publishedJobs: 222,
    url: "https://hubspot.com",
  },
  {
    name: "Atlassian",
    id: "atlassian",
    icon: "/30_Atlassian_logo_logos-1024.png",
    remote: "Yes",
    employees: "10,000+",
    countries: ["us", "au", "in"],
    publishedJobs: 222,
    url: "https://atlassian.com",
  },
  {
    name: "Stripe",
    id: "stripe",
    icon: "/stripe.svg",
    remote: "Yes",
    employees: "7,000+",
    countries: ["us", "ie", "gb", "de"],
    publishedJobs: 222,
    url: "https://stripe.com",
  },
  {
    name: "Mozilla",
    id: "mozilla",
    icon: "/mozilla.svg",
    remote: "Yes",
    employees: "750+",
    countries: ["us", "de", "ca", "fr", "gb"],
    publishedJobs: 222,
    url: "https://mozilla.com",
  },
  {
    name: "NVIDIA",
    id: "nvidia",
    icon: "/nvidia.svg",
    remote: "Yes",
    employees: "26,000+",
    url: "https://nvidia.com",
  },
  {
    name: "MongoDB",
    id: "mongo",
    icon: "/mongo.svg",
    url: "https://mongodb.com",
    remote: "Yes",
    employees: "",
  },
  {
    name: "Datadog",
    id: "datadog",
    icon: "/datadog.svg",
    url: "https://www.datadoghq.com/",
    remote: "Yes",
    employees: "",
  },
  {
    name: "AWS",
    id: "aws",
    icon: "/aws.svg",
    url: "https://aws.amazon.com/",
    remote: "No",
    employees: "",
  },
  {
    name: "Personio",
    id: "personio",
    icon: "/personio.svg",
    url: "https://personio.com",
  },
  {
    name: "Uber",
    id: "uber",
    icon: "/uber.svg",
    url: "https://uber.com",
  },
  {
    name: "eBay",
    id: "ebay",
    icon: "/ebay.svg",
    url: "https://ebay.com",
  },
  {
    name: "GitLab",
    id: "gitlab",
    icon: "/gitlab.svg",
    url: "https://gitlab.com",
  },
  {
    name: "Miro",
    id: "miro",
    icon: "/miro.svg",
    url: "https://miro.com",
  },
  {
    name: "RevenueCat",
    id: "revenuecat",
    icon: "/revenuecat.png",
    url: "https://revenuecat.com",
  },
  {
    name: "Databricks",
    id: "databricks",
    icon: "/databricks.svg",
    url: "https://databricks.com",
  },
  {
    name: "Booking.com",
    id: "booking",
    icon: "/booking.svg",
    url: "https://booking.com",
  },
  {
    name: "Delivery Hero",
    id: "deliveryhero",
    icon: "/deliveryhero.svg",
    url: "https://deliveryhero.com",
  },
  {
    name: "Wayfair",
    id: "wayfair",
    icon: "/wayfair.svg",
    url: "https://wayfair.com",
  },
  {
    name: "Snowflake",
    id: "snowflake",
    icon: "/snowflake.svg",
    url: "https://snowflake.com",
  },
  {
    name: "Adobe",
    id: "adobe",
    icon: "/adobe.svg",
    url: "https://adobe.com",
  },
  {
    name: "Monzo",
    id: "monzo",
    icon: "/monzo.svg",
    url: "https://monzo.com",
  },
  {
    name: "Deliveroo",
    id: "deliveroo",
    icon: "/deliveroo.svg",
    url: "https://deliveroo.com",
  },
  {
    name: "Phantom",
    id: "phantom",
    icon: "/phantom.svg",
    url: "https://phantom.app",
  },
  {
    name: "Kraken",
    id: "kraken",
    icon: "/kraken.svg",
    url: "https://kraken.com",
  },
  {
    name: "Elastic",
    id: "elastic",
    icon: "/elastic.svg",
    url: "https://elastic.co",
  },
  {
    name: "Palantir",
    id: "palantir",
    icon: "/palantir.svg",
    url: "https://palantir.com",
  },
  {
    name: "Squarespace",
    id: "squarespace",
    icon: "/squarespace.svg",
    url: "https://squarespace.com",
  },
  {
    name: "CircleCI",
    id: "circleci",
    icon: "/circleci.svg",
    url: "https://circleci.com",
  },
  {
    name: "Tinder",
    id: "tinder",
    icon: "/tinder.svg",
    url: "https://tinder.com",
  },
  {
    name: "Quora",
    id: "quora",
    icon: "/quora.svg",
    url: "https://quora.com",
  },
  {
    name: "Yelp",
    id: "yelp",
    icon: "/yelp.svg",
    url: "https://yelp.com",
  },
];
